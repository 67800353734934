import React from 'react';

const About = () => {
    return (
        <main className="main">
            <div className="page-title dark-background" data-aos="fade" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1>About Us</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><a href="/" style={{ textDecoration: 'none' }}>Home</a></li>
                            <li className="current">About Us</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section id="about" className="about section">
                <div className="container section-title" data-aos="fade-up">
                    <h2>About</h2>
                </div>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                            <img
                                src="/about.jpg"
                                className="img-fluid"
                                alt="About"
                                style={{ width: '80%', height: '70%', borderRadius: '10px', transition: 'transform 0.5s' }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            />
                           
                        </div>
                        <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                            <p>Visit Lamu is a premier Tour & Travel Company nestled in the heart of Lamu, Kenya. we specialize in curating unforgettable travel experiences for adventurers from across the globe, allowing them to discover and explore Lamu and its surrounding areas.</p>
                            <p>As the ultimate holiday experts, you can entrust your travel plans to us with confidence, knowing that you're in the most capable hands. Our team has traversed every corner of this breathtaking region, enabling us to craft the perfect holiday tailored to your preferences. From Guided Tours and Short Breaks to Day Tours, we offer a diverse array of holiday options to cater to every traveler's desires.</p>
                            <p>Embark on a journey with Visit Lamu and let us transform your travel dreams into unforgettable realities.</p>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
}

export default About;
