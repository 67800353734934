import React, { useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, TextField, Button, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const services = [
    { name: 'Flights & Airport Pickups', price: 500 },
    { name: 'Sunset Dhow Cruise', price: 300 },
    { name: 'UNESCO Old Town Tour', price: 200 },
    { name: 'Donkey Ride', price: 100 },
    { name: 'Water sports & Activities', price: 450 },
    { name: 'Blue Safari', price: 700 },
    { name: 'Outdoor Photography', price: 250 },
];

const excursions = [
    { name: 'Takwa Ruins Tour', price: 400 },
    { name: 'Snorkelling', price: 350 },
    { name: 'Beach Excursions', price: 150 },
    { name: 'Lamu Archipelago Tour', price: 600 },
];

const Book = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('254');
    const [price, setPrice] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [message, setMessage] = useState('');

    const clientId = 'AQXXER5ARf6ug2ftBRBbKgfLlhGPecpsv9V7bhRjTjFDqjADITdEYnVkzSTem2uDCvfJWLWnIlhOByNO';
    const initialOptions = {
        'client-id': clientId,
        currency: 'USD',
    };

    const handleOptionChange = (event) => {
        const { value } = event.target;
        const selected = [...services, ...excursions].find(option => option.name === value);
        setSelectedOption(value);
        setPrice(selected ? selected.price : 0);
    };

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handleSubmit = () => {
        if (paymentMethod === 'mpesa') {
            fetch('https://node.visitlamu.co/api/push-request', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    amount: price,
                    phoneNumber,
                    name,
                    serviceOrExcursion: selectedOption,
                    date: selectedDate,
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log("STK Push Response:", data);
            })
            .catch(error => {
                console.error("Error initiating STK Push:", error);
            });
        }
    };

    return (
        <main className="main">
            <div className="page-title dark-background" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1>Book</h1>
                </div>
            </div>

            <div className="container card">
                <h2>Book a Service or Excursion</h2>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                />

                <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    className="form-control"
                    placeholderText="Select Service Date"
                />

                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Service or Excursion</InputLabel>
                    <Select value={selectedOption} onChange={handleOptionChange}>
                        <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: 10, fontWeight: 'bold' }}>Services</Typography>
                        {services.map((service) => (
                            <MenuItem key={service.name} value={service.name}>
                                {service.name} - {service.price} Ksh
                            </MenuItem>
                        ))}
                        <Typography variant="subtitle1" color="textSecondary" style={{ marginLeft: 10, fontWeight: 'bold' }}>Excursions</Typography>
                        {excursions.map((excursion) => (
                            <MenuItem key={excursion.name} value={excursion.name}>
                                {excursion.name} - {excursion.price} Ksh
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Payment Method</InputLabel>
                    <Select value={paymentMethod} onChange={handlePaymentChange}>
                        <MenuItem value="paypal">PayPal</MenuItem>
                        <MenuItem value="mpesa">M-Pesa</MenuItem>
                    </Select>
                </FormControl>

                {paymentMethod === 'mpesa' && (
                    <PhoneInput
                        country={'ke'}
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                        inputStyle={{ width: '100%', marginTop: '16px', borderRadius: '4px' }}
                    />
                )}

                {paymentMethod === 'paypal' && (
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                            style={{ layout: 'vertical' }}
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{ amount: { value: price.toString(), } }],
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then(details => {
                                    setMessage(`Transaction completed by ${details.payer.name.given_name}`);
                                });
                            }}
                            onError={(err) => {
                                console.error('Error with PayPal payment', err);
                                setMessage('Error with PayPal payment');
                            }}
                        />
                    </PayPalScriptProvider>
                )}

                {message && <Typography color="error">{message}</Typography>}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!selectedOption || !name || !phoneNumber || !paymentMethod || !selectedDate}
                >
                    Book Now
                </Button>
            </div>
        </main>
    );
}

export default Book;
