import React, { useState, useEffect } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const mobileNavToggle = () => {
    setIsModalOpen((prev) => !prev); // Toggle modal state
    setIsMobileNavActive((prev) => !prev); // Toggle icon state
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Modal box style
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    textDecoration: 'none',
  };

  // Link style
  const linkStyle = {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'inherit',
    display: 'block',
    padding: '8px 0',
    transition: 'color 0.3s',
  };

  const hoverLinkStyle = {
    ...linkStyle,
    '&:hover': {
      color: 'red',
    },
  };

  return (
    <header
      id="header"
      className={`header d-flex align-items-center fixed-top ${showHeader ? 'bg-none' : 'hidden'}`}
      style={{ transition: 'transform 0.3s ease-in-out' }}
    >
      <div className="container position-relative d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center me-auto me-xl-0">
          <img src="/logo.png" alt="Logo" />
        </a>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/lamu">Lamu</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/excursions">Excursions</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
          <IconButton
            className={`mobile-nav-toggle d-xl-none }`}
            onClick={mobileNavToggle}
          >
            {isMobileNavActive ? '✖' : '☰'} {/* Use emoji for icon representation */}
          </IconButton>
        </nav>

        {/* MUI Modal for mobile navigation */}
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="mobile-nav-modal-title"
          aria-describedby="mobile-nav-modal-description"
        >
          <Box sx={style}>
            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
              <CloseIcon />
            </IconButton>
            <ul style={{ listStyleType: 'none', padding: 0, marginTop: '20px' }}>
              <li><Box component="a" href="/" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Home</Box></li>
              <li><Box component="a" href="/lamu" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Lamu</Box></li>
              <li><Box component="a" href="/about" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>About</Box></li>
              <li><Box component="a" href="/services" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Services</Box></li>
              <li><Box component="a" href="/excursions" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Excursions</Box></li>
              <li><Box component="a" href="/contact" sx={{ ...linkStyle, '&:hover': { color: 'red' } }}>Contact Us</Box></li>
            </ul>
          </Box>
        </Modal>

        <a href="tel:+254781888999" className="bi bi-telephone" style={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder' }}>
          +254781888999
        </a>

        <a className="cta-btn" href="/contact">Contact Us</a>
      </div>
    </header>
  );
};

export default Header;
