import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Router components
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from './components/Header';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import About from './components/About'; // Import the About component
import Contact from './components/Contact'; // Import the Contact component
import Excursions from './components/Excursions';
import Services from './components/Services';
import Lamu from './components/Lamu';
import Book from './components/Book';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/aos/aos.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/swiper/swiper-bundle.min.css';
import './assets/css/main.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <div className="App index-page">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Visit Lamu</title>
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <link rel="icon" href="assets/img/favicon.png" />
            <link rel="apple-touch-icon" href="assets/img/apple-touch-icon.png" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&family=Poppins:wght@300;400;500&display=swap" rel="stylesheet" />
          </Helmet>

          <Header />
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/lamu" element={<Lamu />} />
            <Route path="/services" element={<Services />} />
            <Route path="/excursions" element={<Excursions />} />
            <Route path="/book" element={<Book />} />
            {/* Add more routes as needed */}
          </Routes>
          <Footer />
          {/* <a href="/" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
            <i className="bi bi-arrow-up-short"></i>
          </a> */}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
