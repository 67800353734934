import React from 'react';
import './Lamu.css';

const Lamu = () => {
    return (
        <main className="main">
            <div className="page-title dark-background" data-aos="fade" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1 className="title">LAMU</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><a href="/" style={{ textDecoration: 'none' }}>Home</a></li>
                            <li className="current">Lamu</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className='container'>
                <section className="intro-section card" data-aos="fade-up">
                    {/* <div className='row'> */}
                    {/* <div className='col'>
                            <img src="LAMUHOTEL.jpg" alt="Lamu" />
                        </div> */}
                    {/* <div className='col-8'> */}
                    <div className="intro-content">
                        <p>Welcome to Lamu, the island of festivals, where time dances to the rhythm of the ocean waves and whispers of ancient tales linger in the salty air.</p>
                        <p>
                            Nestled along the Kenyan coast, Lamu emerges as a cultural oasis, where tradition and celebration intertwine seamlessly. From the vibrant beats of the Lamu Cultural Festival to the mesmerizing rhythms of the Maulidi Festival, this enchanting island invites you to immerse yourself in a kaleidoscope of colors, sounds, and traditions.
                        </p>
                        {/* </div> */}
                        {/* </div> */}
                    </div>

                </section>

                {/** Festival Sections */}
                <section className="festival-section card" data-aos="fade-up" >

                    <div className="festival-content">
                        <p style={{ fontWeight: 'bolder', textAlign: 'left' }}>Lamu Cultural Festival</p>
                        <img src="lamu-cultural.jpg" alt="Lamu Cultural Festival" className="festival-image" />
                        <div className="festival-text mt-3">
                            <p>
                                The Lamu Cultural Festival is an annual celebration showcasing the island's vibrant Swahili heritage through music, dance, art, and cuisine. Experience thrilling dhow races, traditional performances, and donkey races amidst a backdrop of local handicraft showcases.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="festival-section card" data-aos="fade-up">

                    <div className="festival-content">
                        <p style={{ fontWeight: 'bolder', textAlign: 'left' }}>Lamu Maulid Festivals</p>
                        <img src="lamu-maulid.jpg" alt="Lamu Maulid Festival" className="festival-image" />
                        <div className="festival-text  mt-3">
                            <p>
                                The Lamu Maulid Festival is an annual celebration honoring the Prophet Muhammad's birthday, blending religious observance with cultural festivities. It features vibrant processions, traditional music, and captivating performances.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="festival-section card" data-aos="fade-up">

                    <div className="festival-content">
                        <p style={{ fontWeight: 'bolder', textAlign: 'left' }}>Lamu Yoga Festival</p>
                        <img src="lamu-yoga.jpg" alt="Lamu Yoga Festival" className="festival-image" />
                        <div className="festival-text  mt-3">
                            <p>
                                Once a year, the Lamu Yoga Festival offers a blissful escape on the tranquil shores of Lamu Island. Led by renowned instructors, immerse yourself in beachside yoga sessions amidst breathtaking views.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="festival-section card" data-aos="fade-up">

                    <div className="festival-content">
                        <p style={{ fontWeight: 'bolder', textAlign: 'left' }}>Lamu Food Festival</p>
                        <img src="lamu-food.jpg" alt="Lamu Food Festival" className="festival-image" />
                        <div className="festival-text  mt-3">
                            <p>
                                The Lamu Food Festival celebrates the rich culinary heritage of the island with an annual feast for the senses. Indulge in a tantalizing array of local delicacies, from aromatic spices to fresh seafood delights.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="festival-section card" data-aos="fade-up">

                    <div className="festival-content">
                        <p style={{ fontWeight: 'bolder', textAlign: 'left' }}>Lamu Hat Contest</p>
                        <img src="lamu-hat.jpg" alt="Lamu Hat Contest" className="festival-image" />
                        <div className="festival-text  mt-3">
                            <p>
                                The Lamu Hat Contest is a vibrant annual event celebrating creativity and style on the island. Participants showcase unique hats, from traditional Swahili designs to modern interpretations.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Lamu;
