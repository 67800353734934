import React from 'react';

const Services = () => {
    return (
        <main className="main">
            <div className="page-title dark-background" data-aos="fade" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1>Services</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><a href="/" style={{ textDecoration: 'none' }}>Home</a></li>
                            <li className="current">Services</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section id="services" className="services section">
                {/* Section Title */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>Services</h2>
                    {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> */}
                </div>
                {/* End Section Title */}

                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="100">
                            <div className="service-item position-relative">
                                <img
                                    src="/flights.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Flights & Airport Pickups</h3>
                                </a>
                                <p>Start your Lamu adventure with ease by booking our inclusive flight and airport transfer package. We take care of all the logistics, ensuring a seamless journey from your departure point to the enchanting island of Lamu. Sit back, relax, and let us handle the details while you anticipate the unforgettable experiences that await you.
                                </p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="200">
                            <div className="service-item position-relative">
                                <img
                                    src="/lamu_dhow.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Sunset Dhow Cruise</h3>
                                </a>
                                <p>Experience the magic of Lamu's stunning sunsets aboard a traditional dhow. Sail along the tranquil waters of the Indian Ocean as the sun dips below the horizon, painting the sky in a kaleidoscope of colors. Relax, unwind, and create memories that will last a lifetime.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="300">
                            <div className="service-item position-relative">
                                <img
                                    src="/unesco.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>UNESCO Old Town Tour ​</h3>
                                </a>
                                <p>Step back in time as you wander through the narrow, winding streets of Lamu's UNESCO-listed Old Town. Immerse yourself in the unique Swahili architecture, visit historic landmarks, and learn about the town's fascinating history from our knowledgeable guides.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="400">
                            <div className="service-item position-relative">
                                <img
                                    src="/donkey.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Donkey Ride</h3>
                                </a>
                                <p>Embark on a charming donkey ride through the picturesque streets of Lamu. Let these friendly and docile animals be your guide as you explore the town at a leisurely pace. It's a delightful way to soak in the laid-back atmosphere and immerse yourself in the local way of life.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="500">
                            <div className="service-item position-relative">
                                <img
                                    src="/watersports.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Water sports & Activities</h3>
                                </a>
                                <p>Dive into adventure with our range of water sports and activities. Whether you're a novice or an experienced enthusiast, there's something for everyone. Enjoy snorkeling in the vibrant coral reefs, try your hand at paddle boarding along the scenic coastline, or feel the thrill of windsurfing in the gentle ocean breeze. Our experienced instructors will ensure a safe and exciting experience, allowing you to make the most of Lamu's pristine waters.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="600">
                            <div className="service-item position-relative">
                                <img
                                    src="/bluesafari.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Blue Safari</h3>
                                </a>
                                <p>Embark on a journey of unparalleled beauty and cultural richness with Blue Safari in Lamu. From enchanting dhow cruises to guided tours through the maze-like streets of Lamu Town, our immersive experiences unveil the essence of this coastal gem. Indulge in luxurious accommodations, guided by our passionate experts, who curate personalized adventures tailored to your desires.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="600">
                            <div className="service-item position-relative">
                                <img
                                    src="/photography.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Outdoor Photography</h3>
                                </a>
                                <p>Immerse yourself in the picturesque beauty and cultural richness of Lamu with our outdoor photography experiences. From the golden shores of Shela Beach to the labyrinthine streets of Lamu Town, our seasoned guides will lead you to breathtaking vistas and candid moments that embody the soul of this coastal paradise. Whether you're framing the vibrant hues of a bustling market or the tranquil serenity of mangrove forests, our personalized guidance ensures you capture the essence of Lamu with every click of the shutter, preserving its timeless allure for generations to come.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="600">
                            <div className="service-item position-relative">
                                <img
                                    src="/lamuneese.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Lamuneese Cooking Class</h3>
                                </a>
                                <p>Step into a world of warmth, flavor, and heritage with our Lamunese Cooking Class, designed especially for women who cherish the art of cooking and seek authentic cultural connections. In the heart of Lamu, this class offers you an opportunity to explore the timeless traditions of Swahili cuisine alongside local women chefs who share not only their recipes but also their stories, passion, and love for the culinary arts.

                                    Guided through each step, you’ll learn to prepare cherished dishes like spicy Swahili curries, fresh seafood delicacies, and traditional sweets, all crafted with the freshest ingredients and infused with the unique spices of the coast. Beyond cooking, this class invites you to experience the essence of Lamu’s vibrant culture, creating beautiful connections over shared meals and warm conversation.

                                    More than just a cooking lesson, this is a hands-on journey into the heart of Lamunese heritage, where you’ll leave not only with new skills but also with a deeper connection to the women, stories, and spirit of Lamu. Embrace this unique experience, and let’s celebrate sisterhood, tradition, and the art of creating together.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Services;
