import React from 'react';

const Excursions = () => {
    return (
        <main className="main">
            <div className="page-title dark-background" data-aos="fade" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1>Excursions</h1>                    
                    <nav className="breadcrumbs">
                        <ol>
                            <li><a href="/" style={{textDecoration:'none'}}>Home</a></li>
                            <li className="current">Excursions</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section id="services" className="services section">
                {/* Section Title */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>Excursions</h2>
                    {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> */}
                </div>
                {/* End Section Title */}

                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="100">
                            <div className="service-item position-relative">
                                <img
                                      src="/takwa.jpg"
                                      className="img-fluid service-image"
                                      alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Takwa Ruins Tour</h3>
                                </a>
                                <p>Journey to the ancient ruins of Takwa and uncover the mysteries of this deserted Swahili settlement. Marvel at the well-preserved architecture, including the remnants of mosques, houses, and fortifications. Our expert guides will regale you with tales of Takwa's storied past and its significance in Lamu's history.
                                </p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="200">
                            <div className="service-item position-relative">
                                <img
                                    src="/snorkelling.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Snorkelling</h3>
                                </a>
                                <p>Experience Lamu's vibrant underwater world. Dive into crystal-clear waters, encounter sea turtles, and explore colorful coral reefs. Whether you're a seasoned snorkeler or a first-timer, create unforgettable memories beneath the waves. Join us for an immersive journey into the sea's depths.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="300">
                            <div className="service-item position-relative">
                                <img
                                    src="/beachexcursions.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Beach Excursions</h3>
                                </a>
                                <p>Manda Island & Kipungani: Indulge in a day of sun, sea, and sand on the pristine beaches of Manda Island and Kipungani. Relax on soft, white sands, swim in crystal-clear waters, and soak up the tropical sunshine. Whether you're seeking tranquility or adventure, our beach excursions offer something for everyone.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                        <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-delay="400">
                            <div className="service-item position-relative">
                                <img
                                    src="/achipelago.jpg"
                                    className="img-fluid service-image"
                                    alt="About"
                                />
                                <a href="/book" className="stretched-link">
                                    <h3>Lamu Archipelago Tour</h3>
                                </a>
                                <p>Discover the breathtaking beauty of the Lamu Archipelago with a guided tour of its stunning islands. Visit pristine beaches, explore hidden coves, and soak up the serene atmosphere of this tropical paradise. Learn about the rich biodiversity and cultural heritage of the archipelago from our knowledgeable guides as you cruise through its crystal-clear waters.</p>
                                <br />
                                <a className="btn btn-lg btn-danger cta-btn" style={{ textDecoration: 'none' }} href="/book">Book Now</a>
                            </div>
                        </div>
                        {/* End Service Item */}

                    </div>
                </div>
            </section>
        </main>
    );
}

export default Excursions;
