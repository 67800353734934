import React,{ useState } from 'react';

const Contact = () => {

    const [formData, setFormData] = useState({ name: '', email: '', subject: '', message: '' });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://node.visitlamu.co/api/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                alert("Your message has been sent. Thank you!");
            } else {
                alert("Failed to send the message.");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred while sending the message.");
        }
    };

    return (
        <main className="main">
            <div className="page-title dark-background" data-aos="fade" style={{ backgroundImage: "url(about.jpg)" }}>
                <div className="container position-relative">
                    <h1>Contact Us</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><a href="/" style={{ textDecoration: 'none' }}>Home</a></li>
                            <li className="current">Contact Us</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section id="contact" className="contact section light-background">
                {/* Section Title */}
                <div className="container section-title" data-aos="fade-up">
                    <h2>Contact</h2>

                </div>
                {/* End Section Title */}

                <div className="container" data-aos="fade" data-aos-delay="100">
                    <div className="row gy-4">
                        <div className="col-lg-4">
                            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                                <i className="bi bi-geo-alt flex-shrink-0"></i>
                                <div>
                                    <h3>Address</h3>
                                    <p>Ibnusina clinic opposite Lamu Palace Hotel</p>
                                </div>
                            </div>
                            {/* End Info Item */}

                            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                                <i className="bi bi-telephone flex-shrink-0"></i>
                                <div>
                                    <h3>Call Us</h3>
                                    <p><a href='tel:+254781888999' style={{ textDecoration: 'none' }}> +254781888999</a></p>
                                </div>
                            </div>
                            {/* End Info Item */}

                            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                                <i className="bi bi-envelope flex-shrink-0"></i>
                                <div>
                                    <h3>Email Us</h3>
                                    <p><a href='mailto:info@visitlamu.co' style={{ textDecoration: 'none' }}>info@visitlamu.co</a></p>
                                </div>
                            </div>
                            {/* End Info Item */}
                        </div>

                        <div className="col-lg-8">
                            <form onSubmit={handleSubmit} method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <input type="text" name="name" className="form-control" placeholder="Your Name" required onChange={handleChange} />
                                    </div>

                                    <div className="col-md-6">
                                        <input type="email" className="form-control" name="email" placeholder="Your Email" required onChange={handleChange} />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="subject" placeholder="Subject" required onChange={handleChange} />
                                    </div>

                                    <div className="col-md-12">
                                        <textarea className="form-control" name="message" rows="6" placeholder="Message" required onChange={handleChange}></textarea>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>

                                        <button type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* End Contact Form */}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Contact;
